<template>
    <div class="py-6 lg:py-24">
        <div class="">
            <div
                class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <h2 class="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Handle all your Inbound Calls.
                </h2>
                <dl class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
                    <div v-for="feature in features" :key="feature.name">
                        <dt class="text-base font-semibold leading-7 text-gray-900">
                            <div class="pb-4">
                                <RoundedIcon :icon="feature.icon"/>
                            </div>

                            {{ feature.name }}
                        </dt>
                        <dd class="mt-1 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script setup>
import {mdiPhoneCheckOutline, mdiHumanGreetingVariant} from "@mdi/js";
import RoundedIcon from "@/Components/Marketing/RoundedIcon.vue";

const features = [
    {
        name: 'Use Greetmate as a Virtual Receptionist.',
        description:
            'Always available, 24/7. Greetmate can handle all your calls and ensure that your customers are always greeted with a smile.',
        icon: mdiPhoneCheckOutline,
    },
    {
        name: 'Powerful and Liked by Humans.',
        description:
            'Handle any Call Scenario with our powerful and easy workflow builder. Your customers will love the experience.',
        icon: mdiHumanGreetingVariant,
    },
]
</script>
